/* styles.css */
.custom-phone-input input {
  height: 25px !important; /* Hauteur de l'input */
  font-size: 16px !important; /* Taille de la police */
  border: 1px solid #ccc !important; /* Contour gris */
  border-radius: 4px !important; /* Coins légèrement arrondis */
  padding-left: 10px !important; /* Padding pour espacer légèrement le texte du bord */
}

.custom-phone-input input:focus {
  border-color: #66afe9 !important; /* Couleur du contour bleu clair lorsqu'on sélectionne l'input */
  outline: none !important; /* Retirer le contour par défaut du navigateur */
}
